import React, { lazy, Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import PrivateRoute from "../utils/PrivateRoute";
import Home from "../pages/Home";
import PageSkeleton from "../component/skelton/PageSkelton";
import UnsubscribePage from "../pages/UnsubscribeLandingPage";
import Producturl from "../pages/ProductUrl";
import ProductSearchForm from "../pages/demo";
const Auth = lazy(() => import("../layout/Auth"));
const LazyRegister = lazy(() => import("../component/auth/Register"));
const LazyLogin = lazy(() => import("../component/auth/Login"));
const LazyBrandPage = lazy(() => import("../pages/BrandAnalysis"));
const LazyTrendAnalysis = lazy(() => import("../pages/TrendAnalysis"));
const LazyFeedbackAnalysis = lazy(() => import("../pages/FeedbackAnalysis"));
const LazyDashboard = lazy(() => import("../layout/Dashboard"));
const LazyProductResearch = lazy(() =>
  import("../component/ProductResearchChat")
);
const LazyNewBrandPageData = lazy(() => import("../pages/LandingPage"));
const LazyCompetitorAnalysis = lazy(() =>
  import("../pages/CompetitorAnalysis")
);
const AppRoutes = () => {
  return (
    <Suspense>
      <Routes>
        <Route
          path='/'
          element={
            <PrivateRoute>
              <Home></Home>
            </PrivateRoute>
          }
        />
        <Route></Route>

        <Route
          path='/auth'
          element={
            <Suspense fallback={<PageSkeleton />}>
              <Auth />
            </Suspense>
          }
        >
          <Route path='login' element={<LazyLogin />} />
          <Route path='register' element={<LazyRegister />} />
        </Route>
        <Route
          path='/dashboard'
          element={
            <PrivateRoute>
              <LazyDashboard />
            </PrivateRoute>
          }
        >
          <Route
            path='brand-analysis'
            element={
              <Suspense fallback={<PageSkeleton />}>
                {" "}
                <LazyBrandPage />
              </Suspense>
            }
          />

          <Route
            path='trend-analysis'
            element={
              <Suspense fallback={<PageSkeleton />}>
                {" "}
                <LazyTrendAnalysis />
              </Suspense>
            }
          />

          <Route
            path='feedback-analysis'
            element={
              <Suspense fallback={<PageSkeleton />}>
                {" "}
                <LazyFeedbackAnalysis />
              </Suspense>
            }
          />
          <Route path='product-url' element={<Producturl />}></Route>
          <Route
            path='competitor-analysis'
            element={<LazyCompetitorAnalysis />}
          />
          <Route
            path='product-research-chat'
            element={<LazyProductResearch />}
          />
        </Route>
        <Route path='*' element={<Navigate to='/' replace />} />
        <Route path='/unsubscribe-page' element={<UnsubscribePage />} />
        <Route path='/sov' element={<ProductSearchForm />} />
        <Route
          path='brand/:brandId'
          element={
            <Suspense>
              {" "}
              <LazyNewBrandPageData />
            </Suspense>
          }
        />
        {/* <Route path='/productUrl' element={<Producturl />}></Route> */}
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
