import React from "react";
import ReactDOM from "react-dom/client";
import { SnackbarProvider } from "notistack";
import { Provider } from "react-redux";
import posthog from "posthog-js";
import App from "./App";
import { GlobalContextProvider } from "./provider/context/userContext";
import { store } from "./provider/store/index";
import { DashboardContextProvider } from "./provider/context/dashboardContext";
import "./index.css";

const apiKey = process.env.REACT_APP_PUBLIC_POSTHOG_KEY;

posthog.init(apiKey, {
  api_host: "https://us.i.posthog.com",
  autocapture: false
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <SnackbarProvider
      ma
      xSnack={5}
      anchorOrigin={{ horizontal: "right", vertical: "top" }}
    >
      <GlobalContextProvider>
        <DashboardContextProvider>
          <App />
        </DashboardContextProvider>
      </GlobalContextProvider>
    </SnackbarProvider>
  </Provider>
);
