import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import downIcon from "../../assets/images/chevron-down.svg";

/**
 * CustomDropdown component with support for single and multiple selection.
 * @param {Object} props - Component properties.
 * @param {string} props.title - The title displayed in the closed state.
 * @param {boolean} props.multiple - Flag to enable multiple selection.
 * @param {Array} props.options - Array of available options.
 * @param {function} props.onChange - Callback function when an option is selected.
 * @param {string|Array} props.value - The selected value(s).
 * @returns {JSX.Element} - CustomDropdown component.
 */

const CustomDropdown = ({
  title = "Select",
  multiple = false,
  options = [],
  onChange,
  value,
  valueToShow,
  filter,
  sort = true,
  allText = true,
  showProduct = [],
  width,
  Alltoshow = {}
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedValues, setSelectedValues] = useState(
    Array.isArray(value) ? value : [value]
  );
  useEffect(() => {
    // Update selected values when external value prop changes
    setSelectedValues(Array.isArray(value) ? value : [value]);
  }, [value]);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = selectedValue => {
    if (multiple) {
      onChange(selectedValue, filter, "value");
    } else {
      setSelectedValues([selectedValue]);
      onChange(selectedValue);
      handleClose(); // Close the dropdown for single selection
    }
  };

  const renderOptionsInChunks = optionsArray => {

    // Define the chunk size (e.g., 5 items per chunk)
    const chunkSize = 5;

    // Calculate the total number of chunks needed
    const totalChunks = Math.ceil(optionsArray.length / chunkSize);
    if (title === 'Brand view') console.log(totalChunks)
    // Create an array of lengths based on the total number of chunks
    const chunkArray = Array.from({ length: totalChunks }, (_, index) => index);
    if (title === 'Brand view') console.log(chunkArray)
    // Render the chunks of options
    return chunkArray.map(chunkIndex => {
      // Calculate the start and end indices of the current chunk
      const startIndex = chunkIndex * chunkSize;
      const endIndex = Math.min(startIndex + chunkSize, optionsArray.length);

      // Get the current chunk of options
      const currentChunk = optionsArray.slice(startIndex, endIndex);
      if (title === 'Brand view') console.log(valueToShow)
      // Render the current chunk of options
      return (
        <Box
          key={chunkIndex}
          sx={{
            marginBottom: "8px",
            borderLeft: "1px solid #f2f2f2",
            paddingLeft: "8px"
          }}
        >
          {currentChunk.map(option => (
            <MenuItem
              key={option}
              onClick={() => handleOptionClick(option)}
              sx={{
                fontSize: "14px",
                padding: "7px 10px",
                fontFamily: "satoshi",
                fontWeight: "700",
                color: selectedValues.includes(option) ? "#1976D2" : "inherit"
              }}
            >
              {multiple && (
                <Checkbox checked={selectedValues.includes(option)} />
              )}
              <span
                style={{
                  fontSize: "14px",
                  padding: "0px",
                  paddingRight: "5px",
                  fontFamily: "satoshi",
                  fontWeight: "700"
                }}
              >
                {multiple
                  ? valueToShow?.[`${option}`]
                    ? valueToShow[`${option}`]
                    : option
                  : valueToShow?.[`${option}`]
                    ? valueToShow[`${option}`]
                    : option}
              </span>
              <span
                style={{
                  fontSize: "13px",
                  fontFamily: "satoshi",
                  fontWeight: "700"
                }}
              >
                {showProduct?.length > 0 &&
                  `(${showProduct?.filter(item => item[title] === option)?.length
                  })`}
              </span>
            </MenuItem>
          ))}
        </Box>
      );
    });
  };
  return (
    <Box sx={{ background: "none" }} className='sidebar-css'>
      <Button
        onClick={handleClick}
        sx={{
          ":hover": {
            overflow: "visible", // Allow overflow on hover
          },
          height: "40px", // Set a fixed height for the button
          color: "#5C677D",
          background: "#f2f2f2",
          fontSize: ".845rem",
          fontFamily: "satoshi",
          fontWeight: "700",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: width || "100%",
          maxWidth: width || "100%",
          minWidth: width || "100%",
          gap: ".8rem",
          overflow: "hidden", // Hide overflow by default
          textOverflow: "ellipsis", // Add ellipsis for overflow
          whiteSpace: "nowrap", // Ensure the text doesn't wrap
        }}
      >
        <span
          style={{
            color: "#5C677D",
            fontSize: ".845rem",
            fontFamily: "satoshi",
            fontWeight: "700",
            overflow: "hidden", // Truncate text
            textOverflow: "ellipsis", // Add ellipsis for long text
            whiteSpace: "nowrap", // Prevent text from wrapping
            maxWidth: "calc(100% - 20px)", // Adjust to leave space for the icon
          }}
          title={
            multiple
              ? title
              : valueToShow
                ? valueToShow?.[`${value}`]
                  ? valueToShow?.[`${value}`]
                  : `Select ${title}`
                : value
                  ? value
                  : `Select ${title}`
          } // Tooltip to show full text on hover
        >
          {multiple
            ? title
            : valueToShow
              ? valueToShow?.[`${value}`]
                ? valueToShow?.[`${value}`]
                : `Select ${title}`
              : value
                ? value
                : `Select ${title}`}
          {multiple ? ` (${selectedValues.length})` : null}
        </span>
        <img
          src={downIcon}
          alt=""
          style={{
            transform: Boolean(anchorEl) ? "rotate(-180deg)" : "rotate(0)",
          }}
        />
      </Button>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        sx={{
          justifyContent: "center",
          alignItems: "center",

          "& .MuiPaper-root": {
            background: "#fff", // Background color for the Popover content
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
            border: "1px solid #f2f2f2",
            borderRadius: options?.length > 10 ? "50px" : "5px"
          },

          width: "70vw"
        }}
        className='sidebar-css'
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
      >
        <Box
          sx={{ width: "100%", maxHeight: "50vh", overflowY: "auto" }}
          className='sidebar-css'
        >
          {" "}
          <Box
            p={2}
            sx={{
              width: "100%",
              justifyContent: "start",
              maxHeight: "50vh"
            }}
            className='sidebar-css_review sidebar-css'
          >
            {multiple && (
              <Box
                width='100%'
                display='flex'
                sx={{
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <MenuItem
                  onClick={() => handleOptionClick("All")}
                  sx={{
                    // Grow to fill available space
                    fontSize: "16px",
                    padding: "10px",
                    fontFamily: "satoshi",
                    fontWeight: "600",
                    color:
                      options?.length === selectedValues?.length
                        ? "green"
                        : "#000" // Change color for selected item
                  }}
                >
                  <Checkbox
                    checked={options?.length === selectedValues?.length}
                    sx={{
                      color:
                        options?.length === selectedValues?.length
                          ? "green"
                          : "#000"
                    }}
                  />
                  All{" "}
                  {allText
                    ? Alltoshow?.[title]
                      ? Alltoshow?.[title]
                      : title
                    : ""}
                  {allText && !Alltoshow?.[title]
                    ? options?.length > 1
                      ? "s"
                      : ""
                    : ""}
                </MenuItem>
              </Box>
            )}
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: `repeat(${Math.ceil(
                  options?.length / 5
                )}, 1fr)`
              }}
            >
              {renderOptionsInChunks(
                sort
                  ? options
                    ?.slice()
                    ?.sort((a, b) => {
                      if (valueToShow || Number(a) !== NaN) {
                        return b - a
                      } else {
                        return a?.localeCompare(b)
                      }
                      // return valueToShow || Number(a) ? b - a : a?.localeCompare(b)
                    }

                    )
                  : options
              )}
            </Box>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};

export default CustomDropdown;

export const BrandCustomDropdown = ({
  title = "",
  multiple = false,
  options = [],
  onChange,
  value,
  valueToShow,
  filter,
  sort = true,
  showProduct,
  key
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedValues, setSelectedValues] = useState(
    Array.isArray(value) ? value : [value]
  );

  useEffect(() => {
    // Update selected values when external value prop changes
    setSelectedValues(Array.isArray(value) ? value : [value]);
  }, [value]);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = selectedValue => {
    if (multiple) {
      onChange(selectedValue, filter, "value");
    } else {
      setSelectedValues([selectedValue]);
      onChange(selectedValue);
      handleClose(); // Close the dropdown for single selection
    }
  };

  const renderOptionsInChunks = optionsArray => {
    // Define the chunk size (e.g., 5 items per chunk)
    const chunkSize = 5;

    // Calculate the total number of chunks needed
    const totalChunks = Math.ceil(optionsArray.length / chunkSize);

    // Create an array of lengths based on the total number of chunks
    const chunkArray = Array.from({ length: totalChunks }, (_, index) => index);

    // Render the chunks of options
    return chunkArray.map(chunkIndex => {
      // Calculate the start and end indices of the current chunk
      const startIndex = chunkIndex * chunkSize;
      const endIndex = Math.min(startIndex + chunkSize, optionsArray.length);

      // Get the current chunk of options
      const currentChunk = optionsArray.slice(startIndex, endIndex);

      // Render the current chunk of options
      return (
        <Box
          key={chunkIndex}
          sx={{
            marginBottom: "8px",
            borderLeft: "1px solid #f2f2f2",
            paddingLeft: "8px"
          }}
        >
          {currentChunk.map(option => (
            <MenuItem
              key={option}
              onClick={() => handleOptionClick(option)}
              sx={{
                fontSize: "14px",
                padding: "10px",
                fontFamily: "satoshi",
                fontWeight: "700",
                color: selectedValues.includes(option) ? "#1976D2" : "inherit"
              }}
              disabled={
                !selectedValues.includes(option) && selectedValues?.length > 2
              }
            >
              {multiple && (
                <Checkbox checked={selectedValues.includes(option)} />
              )}
              <span
                style={{
                  fontSize: "14px",
                  padding: "10px",
                  fontFamily: "satoshi",
                  fontWeight: "700"
                }}
              >
                {multiple
                  ? option
                  : valueToShow
                    ? valueToShow[`${option}`]
                    : option}
              </span>
              <span
                style={{
                  fontSize: "12px",
                  fontFamily: "satoshi",
                  fontWeight: "700"
                }}
              >
                {showProduct?.length &&
                  `(${showProduct?.filter(item => item[title] === option)?.length
                  })`}
              </span>
            </MenuItem>
          ))}
        </Box>
      );
    });
  };

  return (
    <Box sx={{ background: "none" }} className='sidebar-css'>
      <Button
        onClick={handleClick}
        sx={{
          ":hover": "none",
          color: "#5C677D",
          background: "#f2f2f2",
          fontSize: "12px",
          fontFamily: "satoshi",
          fontWeight: "700",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: ".8rem"
        }}
      >
        <span
          style={{
            color: "#5C677D",
            fontSize: "14px",
            fontFamily: "satoshi",
            fontWeight: "700"
          }}
        >
          {multiple
            ? title
            : valueToShow
              ? valueToShow?.[`${value}`]
                ? valueToShow?.[`${value}`]
                : `Select ${title}`
              : value
                ? value
                : `Select ${title}`}{" "}
          {multiple ? `(${selectedValues.length})` : null}
        </span>
        <img
          src={downIcon}
          alt=''
          style={{
            transform: Boolean(anchorEl) ? "rotate(-180deg)" : "rotate(0)"
          }}
        />
      </Button>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        sx={{
          justifyContent: "center",
          alignItems: "center",

          "& .MuiPaper-root": {
            background: "#fff", // Background color for the Popover content
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
            border: "1px solid #f2f2f2",
            borderRadius: options?.length > 10 ? "50px" : "5px"
          },

          width: "70vw"
        }}
        className='sidebar-css'
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
      >
        <Box
          sx={{ width: "100%", maxHeight: "50vh", overflowY: "auto" }}
          className='sidebar-css'
        >
          {" "}
          <Box
            p={2}
            sx={{
              width: "100%",

              maxHeight: "50vh"
            }}
            className='sidebar-css_review sidebar-css'
          >
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: `repeat(${Math.ceil(
                  options?.length / 5
                )}, 1fr)`
              }}
            >
              {renderOptionsInChunks(
                sort
                  ? options
                    ?.slice()
                    ?.sort((a, b) =>
                      valueToShow ? b - a : a?.localeCompare(b)
                    )
                  : options
              )}
            </Box>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};

export const VariantDropDown = ({
  title = "",
  multiple = false,
  options = [],
  onChange,
  value,
  valueToShow,
  filter,
  sort = true,
  showProduct,
  key
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedValues, setSelectedValues] = useState(
    Array.isArray(value) ? value : [value]
  );

  useEffect(() => {
    // Update selected values when external value prop changes
    setSelectedValues(Array.isArray(value) ? value : [value]);
  }, [value]);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = selectedValue => {
    if (multiple) {
      onChange(selectedValue, filter, "value");
    } else {
      setSelectedValues([selectedValue]);
      onChange(selectedValue);
      handleClose(); // Close the dropdown for single selection
    }
  };

  const renderOptionsInChunks = optionsArray => {
    // Define the chunk size (e.g., 5 items per chunk)
    const chunkSize = 5;

    // Calculate the total number of chunks needed
    const totalChunks = Math.ceil(optionsArray.length / chunkSize);

    // Create an array of lengths based on the total number of chunks
    const chunkArray = Array.from({ length: totalChunks }, (_, index) => index);

    // Render the chunks of options
    return chunkArray.map(chunkIndex => {
      // Calculate the start and end indices of the current chunk
      const startIndex = chunkIndex * chunkSize;
      const endIndex = Math.min(startIndex + chunkSize, optionsArray.length);

      // Get the current chunk of options
      const currentChunk = optionsArray.slice(startIndex, endIndex);

      // Render the current chunk of options
      return (
        <Box
          key={chunkIndex}
          sx={{
            marginBottom: "8px",
            borderLeft: "1px solid #f2f2f2",
            paddingLeft: "8px"
          }}
        >
          {currentChunk.map(option => (
            <MenuItem
              key={option}
              onClick={() => handleOptionClick(option)}
              sx={{
                fontSize: "14px",
                padding: "10px",
                fontFamily: "satoshi",
                fontWeight: "700",
                color: selectedValues.includes(option) ? "#1976D2" : "inherit"
              }}
              disabled={
                !selectedValues.includes(option) && selectedValues?.length > 2
              }
            >
              {multiple && (
                <Checkbox checked={selectedValues.includes(option)} />
              )}
              <span
                style={{
                  fontSize: "14px",
                  padding: "10px",
                  fontFamily: "satoshi",
                  fontWeight: "700"
                }}
              >
                {multiple
                  ? option
                  : valueToShow
                    ? valueToShow[`${option}`]
                    : option}
              </span>
              <span
                style={{
                  fontSize: "12px",
                  fontFamily: "satoshi",
                  fontWeight: "700"
                }}
              >
                {showProduct?.length &&
                  `(${showProduct?.filter(item => item[title] === option)?.length
                  })`}
              </span>
            </MenuItem>
          ))}
        </Box>
      );
    });
  };

  return (
    <Box sx={{ background: "none" }} className='sidebar-css'>
      <Button
        onClick={handleClick}
        sx={{
          ":hover": "none",
          color: "#5C677D",
          background: "#f2f2f2",
          fontSize: "12px",
          fontFamily: "satoshi",
          fontWeight: "700",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: ".8rem"
        }}
      >
        <span
          style={{
            color: "#5C677D",
            fontSize: "14px",
            fontFamily: "satoshi",
            fontWeight: "700"
          }}
        >
          {multiple
            ? title
            : valueToShow
              ? valueToShow?.[`${value}`]
                ? valueToShow?.[`${value}`]
                : `Select ${title}`
              : value
                ? value
                : `Select ${title}`}{" "}
          {multiple ? `(${selectedValues.length})` : null}
        </span>
        <img
          src={downIcon}
          alt=''
          style={{
            transform: Boolean(anchorEl) ? "rotate(-180deg)" : "rotate(0)"
          }}
        />
      </Button>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        sx={{
          justifyContent: "center",
          alignItems: "center",

          "& .MuiPaper-root": {
            background: "#fff", // Background color for the Popover content
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
            border: "1px solid #f2f2f2",
            borderRadius: options?.length > 10 ? "50px" : "5px"
          },

          width: "70vw"
        }}
        className='sidebar-css'
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
      >
        <Box
          sx={{ width: "100%", maxHeight: "50vh", overflowY: "auto" }}
          className='sidebar-css'
        >
          {" "}
          <Box
            p={2}
            sx={{
              width: "100%",

              maxHeight: "50vh"
            }}
            className='sidebar-css_review sidebar-css'
          >
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: `repeat(${Math.ceil(
                  options?.length / 5
                )}, 1fr)`
              }}
            >
              {renderOptionsInChunks(
                sort
                  ? options
                    ?.slice()
                    ?.sort((a, b) =>
                      valueToShow ? b - a : a?.localeCompare(b)
                    )
                  : options
              )}
            </Box>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};
