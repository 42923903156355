import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Button,
  Typography,
  Grid,
  CardMedia,
  Dialog, DialogActions, DialogContent, DialogTitle, TextField
} from "@mui/material";
import feature1 from "../images/image 40.png";
import feature2 from "../images/image 30.png";
import feature3 from "../images/image 20.png";
import feature4 from "../images/image 10.png";
import TopLogo from "../images/image1.4.png";
import TopLogo2 from "../images/image1.6.svg";
import section2 from "../images/section-2.png";
import section21 from "../images/image3rdsection.png";
import Footer from "../component/Footer";
import Navbar from "../component/Navbar";
import { useMediaQuery } from '@mui/material';
import { useGlobalContext } from "../provider/context/userContext";
import {
  Base_Url,
  lwa_app_id,
  lwa_client_secret,
  lwa_application_id
} from "../component/Services/ApiFile";

function Home() {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const globalContext = useGlobalContext();
  const isMdUp = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    // Add your email submission logic here
    console.log("Submitted Email: ", email);
    setOpen(false); // Close the dialog after submitting
    const requestOptions = {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json"
      }),
      body: JSON.stringify({
        email: email
      })
    };

    fetch(Base_Url + "/auth/demo/", requestOptions)
      .then(response => {
        return response.json();
      })
      .then(async data => {
        console.log(data)
      })
      .catch(error => {
        globalContext.Alert("error", error.message);
      });
  };


  const postSpApiData = async (tokenData, marketRegion) => {
    const requestOptions = {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json"
      }),
      body: JSON.stringify({
        user_id: tokenData.user_id,
        user_name: tokenData.user_name,
        seller_type: tokenData.seller_type,
        market_region: tokenData.market_region,
        selling_partner_id: tokenData.selling_partner_id,
        refresh_token: tokenData.refresh_token,
        app_id: lwa_app_id,
        client_secret: lwa_client_secret,
        endpoint: marketRegion["endpoint"],
        market_id: marketRegion["Market_id"]
      })
    };
    const response = await fetch(
      Base_Url + "/info/sp-api-token-collector",
      requestOptions
    );
    if (!response.ok) {
      globalContext.Alert(
        "error,"`Error Connecting to Seller Central, please try again later`
      );
    } else {
      localStorage.removeItem("marketRegion");
      localStorage.removeItem("sellerType");
      localStorage.removeItem("User_id");
      localStorage.removeItem("brandName");
      globalContext.Alert("success", "Review Request Automation Successfull");
      navigate("/auth/login");
    }
  };

  useEffect(() => {
    // local storage Parameters
    const marketRegion = JSON.parse(localStorage.getItem("marketRegion"));
    const sellerType = localStorage.getItem("sellerType");
    const User_id = localStorage.getItem("User_id");
    const brandName = localStorage.getItem("brandName");

    // URI parameters
    const spapi_oauth_code = queryParams.get("spapi_oauth_code");
    const selling_partner_id = queryParams.get("selling_partner_id");
    const amazon_state = decodeURIComponent(queryParams.get("amazon_state"));
    // check the parameters and take respective action
    if (
      amazon_state &&
      amazon_state !== "null" &&
      amazon_state !== "undefined" &&
      marketRegion &&
      sellerType &&
      selling_partner_id
    ) {
      window.location.replace(
        marketRegion[sellerType] +
        `/apps/authorize/confirm/${lwa_application_id}?amazon_state=${amazon_state}&selling_partner_id=${selling_partner_id}&redirect_uri=https://askecho.io`
      );
    } else if (selling_partner_id && spapi_oauth_code) {
      const requestOptions = {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json"
        }),
        body: JSON.stringify({
          grant_type: "authorization_code",
          code: spapi_oauth_code,
          client_id: lwa_app_id,
          client_secret: lwa_client_secret
        })
      };
      async function getData() {
        try {
          const response = await fetch(
            "https://api.amazon.com/auth/o2/token",
            requestOptions
          );
          const data = await response.json();

          let tokenData = {
            user_id: User_id,
            user_name: brandName,
            seller_type: sellerType,
            market_region: marketRegion.region,
            selling_partner_id: selling_partner_id,
            refresh_token: data.refresh_token
          };

          postSpApiData(tokenData, marketRegion);
        } catch (error) {
          globalContext.Alert("error", error.message);
        }
      }
      getData();
    }
  }, []);


  return (
    <Box
      style={{
        width: "100%",
        overflow: "auto hidden"
      }}
    >

      <div
        id='home'
        style={{
          background: "#EDF2F4",
          border: "1px solid #EDF2F4",
          position: "relative", // allow absolute positioning inside
          height: isMdUp ? "100vh" : "", // full viewport height
          overflow: "hidden", // prevent overflow
        }}
      >
        <Navbar />
        <Grid
          container
          id='#home'
          sx={{
            marginTop: { xs: "7rem", md: "7rem" },
            width: "100%",
            height: "100%", // ensure grid takes full height of the parent div
          }}
        >
          {/* Left Grid (Text Section) */}
          <Grid
            item
            xs={12}
            md={5}
            lg={5}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: { xs: "center", md: "left" },
              position: "relative", // allows other elements to overlap
              zIndex: 2, // Keep this on top of CardMedia,
            }}
          >
            <Box
              sx={{

                flexDirection: "column",
                gap: "2rem",
                paddingLeft: { lg: "115px", md: "50px", xs: "0px" },
                width: "100%",
                padding: "auto",
                marginTop: { md: "-30px", xs: "0px" },
                marginLeft: { xs: "0" },
              }}
            >
              <Typography
                variant='h6'
                sx={{
                  fontSize: { xs: "23px" },
                  textAlign: { xs: "center", md: "left" },
                  fontWeight: "700",
                  textTransform: "capitalize",
                  color: "#3F37C9",
                }}
                className='satoshi'
              >
                Unlock
              </Typography>
              <Typography
                variant='h6'
                sx={{
                  color: "#1B263B",
                  fontSize: { xs: "2.5rem", xl: "3rem" },
                  textAlign: { xs: "center", md: "left" },
                  fontWeight: "700",
                  textTransform: "capitalize",
                  lineHeight: { xs: "2.8rem", xl: "3.5rem" },
                }}
                className='satoshi'
              >
                Customer Insights with Echo
              </Typography>
              <Typography
                sx={{
                  color: "#1B263B",
                  fontSize: { xs: "20px" },
                  textAlign: { xs: "center", md: "left" },
                  fontWeight: "700",
                  textTransform: "capitalize",
                  marginBottom: "20px"
                }}
                className='satoshi'
              >
                Now Powered by <span style={{ color: "#3F37C9" }}>Spreetail</span>
              </Typography>
              <Typography
                variant='p'
                sx={{
                  color: "#1B263B",
                  fontSize: { xs: "18px" },
                  fontFamily: "satoshi",
                  textAlign: { xs: "center", md: "left" },
                  lineHeight: "1.7",
                  fontWeight: "400",
                  marginTop: { xs: "30px", md: "30px" },
                }}
              >
                Harness AI to Transform Customer Feedback into Actionable Insights and Boost Sales.
              </Typography>
              <Button
                variant='contained'
                onClick={handleClickOpen}
                sx={{
                  display: "block",
                  margin: { xs: "auto", md: "0px" },
                  marginBottom: { xs: "20px" },
                  marginTop: { xs: "30px", md: "30px" },
                  borderRadius: "100px",
                  width: { md: "226px", xs: "200px" },
                  fontSize: { md: "1.2rem", xs: ".825rem" },
                  backgroundColor: "#3F37C9",
                  textTransform: "capitalize",
                  fontFamily: "satoshi",
                  height: "48px",
                }}
              >
                Get Your Free Demo
              </Button>
            </Box>
          </Grid>

          {/* Right Grid (Image Section) */}
          <Grid
            item
            xs={12}
            md={7}
            lg={7}
            sx={{
              position: "relative",
              height: { md: "100%", xs: "50vh" }
            }}
          >
            {isMdUp ?
              <CardMedia
                component='img'
                image={TopLogo}
                sx={{
                  position: "absolute",
                  top: -110,
                  right: 0,
                  bottom: 0,
                  height: "100vh", // full viewport height to cover the navbar area
                  width: "100%", // cover 60% of the width
                  objectFit: "fill", // cover the area properly
                  zIndex: 1, // make sure it's behind the Navbar
                }}
                alt='company work'
              /> :
              <CardMedia
                component='img'
                image={TopLogo2}
                sx={{
                  height: "50vh", // full viewport height to cover the navbar area
                  width: "100%", // cover 60% of the width
                  objectFit: "fill", // cover the area properly
                  zIndex: 1, // make sure it's behind the Navbar
                }}
                alt='company work'
              />
            }

          </Grid>
        </Grid>
      </div>

      <div
        id='features'
        style={{
          minHeight: "800px"
        }}
      >
        <Grid container sx={{
          marginTop: "40px",
          marginBottom: { md: "20px", xs: "20px" }
        }}>
          <Grid item xs={12}>
            <Typography
              variant='h6'
              sx={{
                fontSize: { xs: "20px" },
                textAlign: "center",
                fontWeight: "700",
                textTransform: "capitalize",
                color: "#3F37C9",
              }}
              className='satoshi'
            >
              Revolutionizing
            </Typography>
            <Typography
              variant='h6'
              sx={{
                fontSize: { xl: "48px", xs: "38px" },
                textAlign: "center",
                fontWeight: "700",
                textTransform: "capitalize",
                color: "#000814",
              }}
              className='satoshi'
            >
              Customer Feedback Analysis with AI
            </Typography>
            <Typography
              variant='h6'
              sx={{
                fontSize: { xs: "14px" },
                textAlign: "center",
                fontWeight: "700",
                // textTransform: "capitalize",
                color: "#000814",
                letterSpacing: "4.5px"
              }}
              className='satoshi'
            >
              ECHO’S AI-POWERED PLATFORM FEATURES
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "20px" }}>
            <Grid container gap={4} pl={{ lg: 11 }} p={{ xs: 4 }} sx={{ width: "100%", padding: "auto" }}>
              <Grid item xs={12} md={5.5} sx={{ background: "#EDF2F4", height: { md: "320px", xs: "340px", lg: "280px" }, paddingTop: "35px", overflow: "auto" }}>
                <img
                  src={feature1}
                  style={{
                    objectFit: "contain",
                    width: "10%",
                    marginLeft: "45%",
                  }}
                  alt='customerFeedback'
                />
                <Typography
                  variant='h6'
                  sx={{
                    fontSize: { xs: "24px" },
                    textAlign: "center",
                    fontWeight: "500",
                    // textTransform: "capitalize",
                    color: "#000814",
                    letter: "0.5px",
                    lineHeight: "33.6px"
                  }}
                  className='satoshi'
                >
                  Omnichannel Customer Sentiment Tracking
                </Typography>
                <Typography
                  variant='h6'
                  sx={{
                    fontSize: { xs: "16px" },
                    width: "80%",
                    marginTop: "20px",
                    marginLeft: "10%",
                    textAlign: "center",
                    fontWeight: "400",
                    // textTransform: "capitalize",
                    color: "#000814",
                    letter: "0.5px",
                    lineHeight: "25.6px"
                  }}
                  className='satoshi'
                >
                  Analyzing customer feedback across multiple channels, helping businesses understand emotions, improve experiences, and make data-driven decisions for better engagement and satisfaction.
                </Typography>
              </Grid>
              <Grid item xs={12} md={5.5} sx={{ background: "#EDF2F4", height: { md: "320px", xs: "340px", lg: "280px" }, paddingTop: "35px", overflow: "auto" }}>
                <img
                  src={feature2}
                  style={{
                    objectFit: "contain",
                    width: "10%",
                    marginLeft: "45%",
                  }}
                  alt='customerFeedback'
                />
                <Typography
                  variant='h6'
                  sx={{
                    fontSize: { xs: "24px" },
                    textAlign: "center",
                    fontWeight: "500",
                    // textTransform: "capitalize",
                    color: "#000814",
                    letter: "0.5px",
                    lineHeight: "33.6px"
                  }}
                  className='satoshi'
                >
                  NPS Tracking And Action Platform
                </Typography>
                <Typography
                  variant='h6'
                  sx={{
                    fontSize: { xs: "16px" },
                    width: "80%",
                    marginTop: "20px",
                    marginLeft: "10%",
                    textAlign: "center",
                    fontWeight: "400",
                    // textTransform: "capitalize",
                    color: "#000814",
                    letter: "0.5px",
                    lineHeight: "25.6px"
                  }}
                  className='satoshi'
                >
                  Measuring customer loyalty by capturing Net Promoter Scores and analyzing detailed feedback, the platform empowers businesses to take targeted actions, enhancing customer satisfaction and driving retention.
                </Typography>
              </Grid>
              <Grid item xs={12} md={5.5} sx={{ background: "#EDF2F4", height: { md: "320px", xs: "340px", lg: "280px" }, paddingTop: "35px", overflow: "auto" }}>
                <img
                  src={feature3}
                  style={{
                    objectFit: "contain",
                    width: "10%",
                    marginLeft: "45%",
                  }}
                  alt='customerFeedback'
                />
                <Typography
                  variant='h6'
                  sx={{
                    fontSize: { xs: "24px" },
                    textAlign: "center",
                    fontWeight: "500",
                    // textTransform: "capitalize",
                    color: "#000814",
                    letter: "0.5px",
                    lineHeight: "33.6px"
                  }}
                  className='satoshi'
                >
                  Data Visualization And Insight Generation
                </Typography>
                <Typography
                  variant='h6'
                  sx={{
                    fontSize: { xs: "16px" },
                    width: "80%",
                    marginTop: "20px",
                    marginLeft: "10%",
                    textAlign: "center",
                    fontWeight: "400",
                    // textTransform: "capitalize",
                    color: "#000814",
                    letter: "0.5px",
                    lineHeight: "25.6px"
                  }}
                  className='satoshi'
                >
                  Transform complex data into clear, actionable insights through intuitive visual representations, helping businesses make informed decisions, identify trends, and optimize performance.
                </Typography>
              </Grid>
              <Grid item xs={12} md={5.5} sx={{ background: "#EDF2F4", height: { md: "320px", xs: "340px", lg: "280px" }, paddingTop: "35px", overflow: "auto" }}>
                <img
                  src={feature4}
                  style={{
                    objectFit: "contain",
                    width: "10%",
                    marginLeft: "45%",
                  }}
                  alt='customerFeedback'
                />
                <Typography
                  variant='h6'
                  sx={{
                    fontSize: { xs: "24px" },
                    textAlign: "center",
                    fontWeight: "500",
                    // textTransform: "capitalize",
                    color: "#000814",
                    letter: "0.5px",
                    lineHeight: "33.6px"
                  }}
                  className='satoshi'
                >
                  Product Performance Analytics
                </Typography>
                <Typography
                  variant='h6'
                  sx={{
                    fontSize: { xs: "16px" },
                    width: "80%",
                    marginTop: "20px",
                    marginLeft: "10%",
                    textAlign: "center",
                    fontWeight: "400",
                    // textTransform: "capitalize",
                    color: "#000814",
                    letter: "0.5px",
                    lineHeight: "25.6px"
                  }}
                  className='satoshi'
                >
                  Monitors and evaluates key metrics like sales, user engagement, and customer feedback, offering valuable insights and highlighting areas for improvement to optimize performance and drive growth.                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>

      <div
        id='spreetail'
        style={{
          background: "#EDF2F4",
          border: "1px solid #EDF2F4",
          position: "relative", // allow absolute positioning inside
          height: isMdUp ? "620px" : "400px", // full viewport height
          overflow: "hidden", // prevent overflow
        }}
      >
        <Grid
          container
          id='#home'
          sx={{
            // border:"2px solid red",
            // marginTop: { xs: "7rem", lg: "7rem" },
            width: "100%",
            height: "100%", // ensure grid takes full height of the parent div
          }}
          gap={{ md: 0, xs: 3 }}
        >
          {/* Left Grid (Text Section) */}
          <Grid
            item
            xs={12}
            md={5}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: { xs: "center", lg: "left" },
              position: "relative", // allows other elements to overlap
              zIndex: 2, // Keep this on top of CardMedia
            }}
          >
            <Box
              sx={{

                flexDirection: "column",
                gap: "2rem",
                paddingLeft: { lg: "115px", xs: "0px" },
                width: "100%",
                padding: "auto",
                marginTop: "-30px",
                marginLeft: { xs: "0" },
              }}
            >
              <Typography
                variant='h6'
                sx={{
                  fontSize: { xs: "20px" },
                  textAlign: { xs: "center", lg: "left" },
                  fontWeight: "700",
                  textTransform: "capitalize",
                  color: "#3F37C9",
                  lineHeight: "20px",
                  letterSpacing: "1.5px",
                  marginBottom: "15px"
                }}
                className='satoshi'
              >
                Empowering Brands in
              </Typography>
              <Typography
                variant='h6'
                sx={{
                  color: "#000814",
                  fontSize: { xs: "38px", md: "48px" },
                  textAlign: { xs: "center", lg: "left" },
                  fontWeight: "700",
                  textTransform: "capitalize",
                  lineHeight: { lg: "57.6px" },
                }}
                className='satoshi'
              >
                Spreetail’s Network
              </Typography>
              <Typography
                sx={{
                  color: "#001D3D",
                  fontSize: { xs: "14px" },
                  textAlign: { xs: "center", lg: "left" },
                  fontWeight: "700",
                  textTransform: "capitalize",
                  lineHeight: "22.4px",
                  letterSpacing: "4.5px",
                  marginBottom: "15px"
                }}
                className='satoshi'
              >
                INTEGRATING WITH SPREETAIL'S NETWORK
              </Typography>
              <Typography
                variant='p'
                sx={{
                  color: "#000814",
                  fontSize: { xs: "16px" },
                  marginTop: "20px",
                  fontFamily: "satoshi",
                  textAlign: { xs: "center", lg: "left" },
                  lineHeight: "1.7px",
                  fontWeight: "400",
                }}
              >
                Echo’s AI capabilities will integrate with Spreetail’s logistics and marketplace acceleration services, offering Brands a complete solution. Now convert customer feedback into key areas of AI powered growth!                </Typography>
            </Box>
          </Grid>

          {/* Right Grid (Image Section) */}
          <Grid
            item
            xs={12}
            md={7}
            sx={{
              position: "relative",
              height: "100%",
            }}

          >
            <CardMedia
              component='img'
              image={isMdUp ? section2 : section21}
              sx={{

                height: isMdUp ? "620px" : "300px", // full viewport height to cover the navbar area
                width: "100%", // cover 60% of the width
                objectFit: "fill", // cover the area properly
                zIndex: 1, // make sure it's behind the Navbar
              }}
              alt='company work'
            />
          </Grid>
        </Grid>
      </div>
      {/* Email Popup Dialog */}
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            position: 'relative',
            bottom: !isMdUp ? '25vh' : 'auto', // Adjust top positioning when screen size is smaller
          },
        }}
      >
        <DialogTitle
          sx={{ fontWeight: 'bold', textAlign: 'center', color: "#3F37C9" }}
        >
          Get personalised demo
        </DialogTitle>
        <DialogContent
          sx={{
            width: isMdUp ? "500px" : "300px",
            padding: "10px 24px", // Consistent padding for content
            marginBottom: "0px",
          }}
        >
          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Email Address"
            type="email"
            fullWidth
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{
              marginBottom: "1rem", // Adjust margin to reduce space below the TextField
              '& .MuiOutlinedInput-root': {
                borderRadius: '8px',
              },
            }}
          />
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: 'center',
            padding: "0px 24px 24px", // Padding for consistent spacing at the bottom
            marginTop: "0", // Remove extra margin between TextField and actions
          }}
        >
          <Button
            onClick={handleClose}
            variant="outlined"
            sx={{
              fontSize: "1rem",
              textTransform: "capitalize",
              borderRadius: "20px",
              padding: "0.5rem 1.5rem",
              marginRight: '1rem',
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            sx={{
              fontSize: "1rem",
              textTransform: "capitalize",
              borderRadius: "20px",
              padding: "0.5rem 1.5rem",
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>



      <Footer />
    </Box>
  );
}

export default Home;
