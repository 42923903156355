export const marketplace = [
  {
    market_id: "54F60079-385D-479E-980A-12CD96A70944",
    market_img_url:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTNBfgpjgvuc5YpXmBj85pqH-1okU_wyNgTiQ&s",
    market_name: "Target",
    marketplace_region: "US",
    prev_market_id: "PTTUS"
  },
  		
  {
    market_id: "C38DCEB9-2A5C-497A-87AE-32A0B8B7A4C1",
    market_img_url:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTL_1oOYjdSVp3fbMXbfx0g4O5Qz7Qs6TRTjA&s",
    market_name: "HomeDepot",
    marketplace_region: "US",
    prev_market_id: "PHDUS"
  },
 
  {
    market_id: "7c39efa2b46543ce855d16acc42f3f86",
    market_name: "Amazon US",
    market_img_url:
      "https://www.freeiconspng.com/thumbs/amazon-icon/amazon-icon--socialmedia-iconset--uiconstock-0.png",
    marketplace_region: "US",
    market_url: "https://www.amazon.com/",
    prev_market_id: "PAUS"
  },
  {
    market_id: "C84943E1-DC4B-41E8-8DA6-CCC51E635181",
    market_name: "Walmart",
    market_img_url:
      "https://purepng.com/public/uploads/large/purepng.com-walmart-vertical-logologobrand-logoiconslogos-2515199388322jxhn.png",
    marketplace_region: "US",
    market_url: "https://www.walmart.com/",
    prev_market_id: "PNUS"
  },
];
