import { createSlice } from "@reduxjs/toolkit";

export const TextSKUSlice = createSlice({
  name: "TextSKUAnalysis",
  initialState: {
    reviewData: null,
    skuData: null
  },
  reducers: {
    addSKUData: (state, action) => {
      // const newMovie = {id:3,title:'Batman'};
      state.skuData = action.payload;
    },
    addReviewData: (state, action) => {
      // const newMovie = {id:3,title:'Batman'};
      state.reviewData = action.payload;
    }
  }
});

export const { addSKUData, addReviewData } = TextSKUSlice.actions;
export default TextSKUSlice.reducer;
