// Import necessary modules and libraries
import React, { createContext, useContext, useState } from "react";
import { addDays } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { useGlobalContext } from "./userContext";
import { Base_Url } from "../../component/Services/ApiFile";
import {
  addFilterData,
  addFilterSelected,
  addMarketList
} from "../store/reducers/brandAnalysis";

// Create a new context
const dashboardContext = createContext();

// Create a custom hook for using the context
export const useDashboardContext = () => useContext(dashboardContext);

// Create a provider component
export const DashboardContextProvider = ({ children }) => {
  const globalContext = useGlobalContext();
  const [product, setProduct] = useState();
  const [productList, setProductList] = useState([]);
  const [marketsList, setMarketsList] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [overviewData, setOverviewData] = useState();
  const [marketIcons, setMarketIcons] = useState([]);
  const [marketPlaceData, setMarketPlaceData] = useState();
  const [marketTableData, setMarketTableData] = useState();
  const [variantsSelected, setVariantsSelected] = useState([]);
  const [topReviewsData, setTopReviewsData] = useState([]);
  const [variantData, setVariantData] = useState();
  const [bsrDownloadData, setBsrDownloadData] = useState([]);
  const [productTrend, setProductTrend] = useState();
  const [showModal, setShowModal] = useState(true);
  const [market, setMarket] = useState([]);
  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection"
    }
  ]);
  const [negativeData, setNegativeData] = useState([]);
  const [negativeMasterIssueDataKapiva, setNegativeMasterIssueDataKapiva] =
    useState([]);
  const [staticChangingData, setStaticChangingData] = useState();
  const [changingdata, setchangingData] = useState();
  const [complainTableData, setComplainTableData] = useState([]);
  const [brandSummary, setBrandSummary] = useState();
  const [weeks, setWeeks] = useState([]);
  const [allReviews, setAllReviews] = useState();
  const [showTable, setShowTable] = useState(false);
  const [aggregation, setAggregation] = useState("weekly");
  const [masterTagsSelected, setMasterTagsSelected] = useState([]);
  const [masterIssueHeaderData, setMasterIssueHeaderData] = useState();
  const [ProductEdit, setProductEdit] = useState();
  const [negativeDownload, setNegativeDownload] = useState();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [npsLoading, setNpsLoading] = useState(false);
  const [overviewLoading, setOverviewLoading] = useState(false);
  const [reviewLoading, setReviewLoading] = useState(false);
  const [variantLoading, setVariantLoading] = useState(false);
  const [positiveData, setPositiveData] = useState();
  const [positiveDownload, setPositiveDownload] = useState();
  const [months, setMonth] = useState();
  const [monthlyNegativeData, setmonthlyNegativeData] = useState();
  const [monthlyPositiveData, setmonthlyPositiveData] = useState();
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [tabs, setTabs] = useState();
  const [dashboardLoading, setDashboardLoading] = useState(false);
  const [competitorProduct, setCompetitorProduct] = useState([]); //
  const [userFilter, setUserFilter] = useState();
  const [allProductList, setAllProductList] = useState([]);
  const [filterData, setFilterData] = useState();
  const [ratingsMarketTableData, setRatingsMarketTableData] = useState();
  const dispatch = useDispatch();
  // Fetch User Data
  const fetchUserData = async () => {
    try {
      globalContext.backdropOpen();

      setDashboardLoading(true);
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${globalContext.userData.token}`
        }
      };
      const response = await fetch(
        Base_Url + "/info/get-all-user-data",
        requestOptions
      );

      const userData = await response.json();
      const ratings = (item, productData) => {
        const product = productData?.filter(product => {
          return product?.master_product_id === item?.id;
        })?.[0];

        return { ...item, ratings: product?.["Average Rating"][1] };
      };
      let temp = [];
      if (userData) {
        const processProductList = (productList, isCompetitor) => {
          return productList.reduce((acc, item) => {
            const masterProduct = item?.master_product;
            if (!(masterProduct?.is_competitor_product !== isCompetitor)) {
              const variants = [...(item.variants || []), masterProduct];
              const data = {
                label: masterProduct.product_name,
                data: masterProduct,
                img: masterProduct.image_url,
                id: masterProduct.master_product_id,
                variant: variants
              };
              acc.push(data);
            }
            return acc;
          }, []);
        };

        const filtered = processProductList(
          userData?.product_list || [],
          false
        );
        const filteredCompetitor = processProductList(
          userData?.product_list || [],
          true
        );

        setCompetitorProduct(filteredCompetitor);

        let requestData = {
          marketplaces: [],
          filters: {},
          marketList: userData?.market_list,
          productList: filtered,
          username: globalContext.userData.username
        };

        try {
          let response = await fetch(
            Base_Url + "/table/get-brand-page-nps-data",
            {
              method: "POST",
              headers: new Headers({
                "Content-Type": "application/json",
                Authorization: `Token ${globalContext.userData.token}`
              }),
              body: JSON.stringify(requestData)
            }
          );

          const brandPageNpsData = await response.json();

          if (!brandPageNpsData) return;
          const [tempdata1, tempData] = brandPageNpsData?.result;
          console.log(tempData);
          if (brandPageNpsData?.result) {
            const productData = tempData?.Reviews?.monthly;
            const productListArray = [];
            filtered?.forEach(item1 => {
              productListArray?.push(ratings(item1, productData));
            });
            setProductList(
              productListArray?.sort((item1, item2) => {
                return item2?.["ratings"] - item1["ratings"];
              })
            );
          }
        } catch (error) {
          console.error(error);
        }
        setProductEdit(filtered);
        setCategoryList(userData.categories);
        setMarketsList(
          userData?.market_list
            ?.slice()
            ?.sort((a, b) => a.market_name.localeCompare(b.market_name))
        );
        dispatch(addMarketList(userData.market_list));
        setTabs(userData.tab_list);
        dispatch(addFilterData(userData?.user_filters));
        setUserFilter(userData?.user_filters);
        setAllProductList([...filtered, ...filteredCompetitor]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setDashboardLoading(false);
      globalContext.backdropClose();
    }
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleModalOpen = () => {
    setShowModal(true);
  };
  // State from Redux (you can replace this with your actual Redux setup)
  const state = useSelector(state => state.TrendAnalysis);
  const contextCleanup = () => {
    const initialState = {
      product: undefined,
      productList: [],
      marketsList: [],
      overviewData: undefined,
      marketIcons: [],
      marketPlaceData: undefined,
      marketTableData: undefined,
      variantsSelected: [],
      topReviewsData: [],
      variantData: undefined,
      productTrend: undefined,
      market: [],
      range: [
        {
          startDate: new Date(),
          endDate: addDays(new Date(), 7),
          key: "selection"
        }
      ],
      negativeData: [],
      negativeMasterIssueDataKapiva: [],
      staticChangingData: undefined,
      changingdata: undefined,
      complainTableData: [],
      brandSummary: undefined,
      weeks: [],
      allReviews: undefined,
      showTable: false,
      aggregation: "weekly",
      masterTagsSelected: [],
      masterIssueHeaderData: undefined,
      ProductEdit: undefined,
      negativeDownload: undefined,
      dataLoaded: false,
      npsLoading: false,
      overviewLoading: false,
      reviewLoading: false,
      variantLoading: false,
      positiveData: undefined,
      positiveDownload: undefined,
      months: undefined,
      monthlyNegativeData: undefined,
      monthlyPositiveData: undefined,
      categoryList: [],
      selectedCategory: null
    };

    setProduct(initialState.product);
    setProductList(initialState.productList);
    setMarketsList(initialState.marketsList);
    setOverviewData(initialState.overviewData);
    setMarketIcons(initialState.marketIcons);
    setMarketPlaceData(initialState.marketPlaceData);
    setMarketTableData(initialState.marketTableData);
    setVariantsSelected(initialState.variantsSelected);
    setTopReviewsData(initialState.topReviewsData);
    setVariantData(initialState.variantData);
    setProductTrend(initialState.productTrend);
    setMarket(initialState.market);
    setRange(initialState.range);
    setNegativeData(initialState.negativeData);
    setNegativeMasterIssueDataKapiva(
      initialState.negativeMasterIssueDataKapiva
    );
    setStaticChangingData(initialState.staticChangingData);
    setchangingData(initialState.changingdata);
    setComplainTableData(initialState.complainTableData);
    setBrandSummary(initialState.brandSummary);
    setWeeks(initialState.weeks);
    setAllReviews(initialState.allReviews);
    setShowTable(initialState.showTable);
    setAggregation(initialState.aggregation);
    setMasterTagsSelected(initialState.masterTagsSelected);
    setMasterIssueHeaderData(initialState.masterIssueHeaderData);
    setProductEdit(initialState.ProductEdit);
    setNegativeDownload(initialState.negativeDownload);
    setDataLoaded(initialState.dataLoaded);
    setNpsLoading(initialState.npsLoading);
    setOverviewLoading(initialState.overviewLoading);
    setReviewLoading(initialState.reviewLoading);
    setVariantLoading(initialState.variantLoading);
    setPositiveData(initialState.positiveData);
    setPositiveDownload(initialState.positiveDownload);
    setMonth(initialState.months);
    setmonthlyNegativeData(initialState.monthlyNegativeData);
    setmonthlyPositiveData(initialState.monthlyPositiveData);
    setCategoryList(initialState.categoryList);
    setSelectedCategory(initialState.selectedCategory);
    setTabs();
    setUserFilter();
  };

  // Create a context value object with all your state variables and functions
  const contextValue = {
    product,
    productList,
    marketsList,
    overviewData,
    marketIcons,
    marketPlaceData,
    marketTableData,
    variantsSelected,
    topReviewsData,
    variantData,
    bsrDownloadData,
    setBsrDownloadData,
    market,
    range,
    negativeData,
    negativeMasterIssueDataKapiva,
    weeks,
    allReviews,
    showTable,
    aggregation,
    masterTagsSelected,
    masterIssueHeaderData,
    ProductEdit,
    graphData,
    setGraphData,
    setProductEdit,
    setProductList,
    marketsList,
    setMarketsList,
    setTopReviewsData,
    setOverviewData,
    setMarketPlaceData,
    setMarketTableData,
    setAllReviews,
    setDataLoaded,
    reviewLoading,
    npsLoading,
    overviewLoading,
    setNpsLoading,
    setMarket,
    setProduct,
    setOverviewLoading,
    setMarketIcons,
    setReviewLoading,
    setVariantLoading,
    setNegativeData,
    negativeDownload,
    dataLoaded,
    overviewLoading,
    variantLoading,
    positiveData,
    positiveDownload,
    months,
    monthlyNegativeData,
    monthlyPositiveData,
    setNegativeMasterIssueDataKapiva,
    setShowTable,
    setMasterIssueHeaderData,
    setVariantData,
    setVariantsSelected,
    setWeeks,
    setMonth,
    setNegativeDownload,
    setPositiveData,
    setPositiveDownload,
    setmonthlyNegativeData,
    setmonthlyPositiveData,
    productTrend,
    setProductTrend,
    setCategoryList,
    competitorProduct,
    categoryList,
    selectedCategory,
    setSelectedCategory,
    brandSummary,
    setBrandSummary,
    setchangingData,
    changingdata,
    setStaticChangingData,
    staticChangingData,
    complainTableData,
    setComplainTableData,
    contextCleanup,
    fetchUserData,
    userFilter,
    tabs,
    dashboardLoading,
    allProductList,
    filterData,
    setFilterData,
    ratingsMarketTableData,
    setRatingsMarketTableData,
    handleClose,
    showModal,
    handleModalOpen
  };

  return (
    <dashboardContext.Provider value={contextValue}>
      {children}
    </dashboardContext.Provider>
  );
};
