import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef
} from "react";
import { useSnackbar } from "notistack";
import { Backdrop } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import jwt_decode from "jwt-decode";
import { Base_Url } from "../../component/Services/ApiFile";
import { useDashboardContext } from "./dashboardContext";

export const GlobalContext = createContext();

export const GlobalContextProvider = props => {
  const storedToken = localStorage.getItem("authToken");
  const initialUserData = storedToken
    ? {
        token: JSON.parse(storedToken).token,
        username: jwt_decode(storedToken).username
      }
    : {};

  const [userData, setUserData] = useState(initialUserData);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [theme, setTheme] = useState("light");
  const [productData, setProductData] = useState({});
  const [similarProductList, setSimilarProductList] = useState([]);
  const [productReviews, setProductReviews] = useState([]);
  const [backdropStatus, setBackdropStatus] = useState(false);
  const [statusMessage, setStatusMessage] = useState();
  const [open, setOpen] = useState(false);
  const [productsColor, setProductsColor] = useState();
  const [SKUFinalData, setSKUFinalData] = useState();
  const [connectedServer, setConnectedServer] = useState(false);
  const [connectionError, setConnectionError] = useState();
  const [extractionStatus, setExtractionStatus] = useState([]);
  const [retryLeft, setRetryLeft] = useState(3);
  const [connectionID, setConnectionID] = useState();
  const [variantTabValue, setVariantTabValue] = useState(0);

  const [loginData, setLoginData] = useState(() =>
    localStorage.getItem("authToken")
      ? jwt_decode(localStorage.getItem("authToken"))
      : null
  );
  const [authToken, setAuthToken] = useState(() =>
    localStorage.getItem("authToken")
      ? JSON.parse(localStorage.getItem("authToken"))
      : null
  );
  const [devMode, setDevMode] = useState(() =>
    localStorage.getItem("dev-mode") ? localStorage.getItem("dev-mode") : false
  );
  const socketRef = useRef();

  const action = snackbarId => (
    <button
      onClick={() => {
        closeSnackbar(snackbarId);
      }}
      style={{
        border: "none",
        backgroundColor: "transparent",
        color: "white",
        cursor: "pointer"
      }}
    >
      <CloseIcon />
    </button>
  );

  const localHostNotPresent = () => {
    const currentUrl = window.location.href;
    return !currentUrl.includes("localhost");
  };
  const devSiteNotPresent = () => {
    const currentUrl = window.location.href;
    return !currentUrl.includes("dev");
  };
  const checkDevMode = () => {
    return !devMode;
  };

  const Alert = (type, message) => {
    if (type && message) {
      enqueueSnackbar(message, {
        variant: type,
        autoHideDuration: 8000,
        action
      });
    }
  };

  const connectWebSocket = async () => {
    if (!connectedServer) {
      if (retryLeft > 0) {
        setRetryLeft(retryLeft - 1);

        socketRef.current = await new WebSocket(
          `${
            (Base_Url.includes("https:") ? "wss://" : "ws://") +
            Base_Url.split("//")[1]
          }/ws/status/${userData.token}`
        );

        socketRef.current.onopen = e => {
          setConnectedServer(true);
          setConnectionError(false);
        };

        socketRef.current.onmessage = e => {
          const data = JSON.parse(e.data);
          if (data.type === "connectionDetails") {
            setConnectionID(data.connectionId);
          }
          if (data.type === "status") {
            setStatusMessage(data.message);
          } else if (data.type === "prodInfo") {
            setProductData(data.productData);
            setStatusMessage(data.message);
          } else if (data.type === "terminal") {
            setExtractionStatus([...extractionStatus, data.message]);
          } else if (data.type === "data") {
            backdropClose();
            setStatusMessage();
            setSKUFinalData(data.result);
            Alert(
              "success",
              "Analysis completed successfully. All review extraction started."
            );
          } else if (data.type === "Infoerror") {
            backdropClose();
            setStatusMessage("Infoerror");
            Alert("error", data.message);
          } else if (data.type === "error") {
            backdropClose();
            setStatusMessage("close");
            Alert("error", data.message);
          }
        };

        socketRef.current.onerror = e => {
          setConnectedServer(false);
          setTimeout(() => connectWebSocket(), 3000);
        };
      } else {
        setConnectionError(true);
      }
    }
  };

  const backdropOpen = () => {
    setBackdropStatus(true);
  };

  const backdropClose = () => {
    setBackdropStatus(false);
  };

  const updateToken = async () => {
    const response = await fetch(`${Base_Url}/auth/token/refresh/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ refresh: authToken?.refresh })
    });
    const data = await response.json();
    if (response.status === 200) {
      data.token = authToken.token;
      setLoginData(jwt_decode(data.access));
      setAuthToken(data);
      localStorage.setItem("authToken", JSON.stringify(data));
    } else {
      localStorage.removeItem("authToken");
      setLoginData(null);
      setAuthToken(null);
      window.location.href = "/auth/login";
    }
  };

  useEffect(() => {
    const interval = setInterval(
      () => {
        if (authToken) {
          updateToken();
        }
      },
      1000 * 60 * 4
    );
    return () => clearInterval(interval);
  }, []);

  return (
    <GlobalContext.Provider
      value={{
        Alert,
        localHostNotPresent,
        theme,
        setTheme,
        userData,
        setUserData,
        productData,
        setProductData,
        similarProductList,
        setSimilarProductList,
        productReviews,
        setProductReviews,
        backdropOpen,
        backdropClose,
        open,
        setOpen,
        productsColor,
        setProductsColor,
        SKUFinalData,
        setSKUFinalData,
        connectedServer,
        connectionError,
        statusMessage,
        setStatusMessage,
        extractionStatus,
        setExtractionStatus,
        connectionID,
        variantTabValue,
        setVariantTabValue,
        loginData,
        setLoginData,
        authToken,
        setAuthToken,
        devSiteNotPresent,
        checkDevMode,
        setDevMode
      }}
    >
      {props.children}
      <Backdrop
        sx={{
          color: "white",
          zIndex: theme => theme.zIndex.drawer + 999999,
          display: "flex",
          flexDirection: "column",
          textShadow: "1px 1px 1px black"
        }}
        open={backdropStatus}
      >
        <img src='/giphy-4-unscreen.gif' alt='loading' width={200} />
        <h3>{statusMessage}</h3>
      </Backdrop>
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = () => useContext(GlobalContext);
