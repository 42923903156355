// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.glassmorphism {
  background: rgba(255, 255, 255) !important;
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  border-radius: 10px;
  padding: 20px;
}
.base_link {
  color: #1b263b !important;
}

.base_link:hover {
  color: orange !important;
}

.nav-link {
  color: #1b263b;
  transition: color 0.3s;
  padding-bottom: 0rem;
  border-bottom: 2px solid transparent;
}
`, "",{"version":3,"sources":["webpack://./src/component/Navbar/style.css"],"names":[],"mappings":"AAAA;EACE,0CAA0C;EAC1C,kCAA0B;UAA1B,0BAA0B;EAC1B,mBAAmB;EACnB,aAAa;AACf;AACA;EACE,yBAAyB;AAC3B;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,cAAc;EACd,sBAAsB;EACtB,oBAAoB;EACpB,oCAAoC;AACtC","sourcesContent":[".glassmorphism {\r\n  background: rgba(255, 255, 255) !important;\r\n  backdrop-filter: blur(4px);\r\n  border-radius: 10px;\r\n  padding: 20px;\r\n}\r\n.base_link {\r\n  color: #1b263b !important;\r\n}\r\n\r\n.base_link:hover {\r\n  color: orange !important;\r\n}\r\n\r\n.nav-link {\r\n  color: #1b263b;\r\n  transition: color 0.3s;\r\n  padding-bottom: 0rem;\r\n  border-bottom: 2px solid transparent;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
