import { createSlice } from "@reduxjs/toolkit";

export const categorySlice = createSlice({
  name: "CategoryAnalysis",

  initialState: {
    masterTab: 0,
    // An array to store selected market options
    marketSelect: [],
    sortedProduct: false,

    // An array to store selected category-product combinations
    categoryProductSelected: [],

    // A number indicating the active tab
    tab: 0,

    // A string representing the selected product category
    productListCategory: "",

    // An object containing overview data
    overViewData: {},

    // An object containing negative feedback data
    negativeData: {},

    // An object containing positive feedback data
    positiveData: {},

    // An object containing UI-related data
    uiData: {},

    // An object containing category-related data
    category: {}
  },
  reducers: {
    addMasterTab: (state, action) => {
      state.masterTab = action.payload;
    },
    addMarketSelect: (state, action) => {
      state.marketSelect = action.payload;
    },

    addOverviewData: (state, action) => {
      state.overViewData = action.payload;
    },

    addNegativeData: (state, action) => {
      state.negativeData = action.payload;
    },

    addPositiveData: (state, action) => {
      state.positiveData = action.payload;
    },

    addTab: (state, action) => {
      state.tab = action.payload;
    },

    addListingData: (state, action) => {
      state.listingData = action.payload;
    },

    addListingMarketplace: (state, action) => {
      state.listingMarketplace = action.payload;
    },

    addUiData: (state, action) => {
      state.uiData = action.payload;
    },
    addCategory: (state, action) => {
      state.category = action.payload;
    },
    addProductCategoryList: (state, action) => {
      state.productListCategory = action.payload;
    },
    addCategoryProductSelected: (state, action) => {
      state.categoryProductSelected = action.payload;
    },
    addProductSorted: (state, action) => {
      state.sortedProduct = action.payload;
    },
    storeCleanupFeedback: (state, action) => {
      state.tab = 0;
      state.variantData = [];
      state.variantSelect = [];
      state.marketSelect = [];
      state.overViewData = {};
      state.positiveData = [];
      state.negativeData = [];
      state.master_product_id = [];
      state.product = [];
      state.listingData = [];
      state.productListCategory = [];
      state.categoryProductSelected = [];
      state.category = {};
      state.sortedProduct = false;
      state.masterTab = 0;
    }
  }
});

export const {
  addMarketSelect,
  addVariantSelect,
  addPositiveData,
  addNegativeData,
  addOverviewData,
  addMasterProduct,
  storeCleanupFeedback,
  addTab,
  addListingData,
  addUiData,
  addListingMarketplace,
  addCategoryProductSelected,
  addProductCategoryList,
  addCategory,
  addProductSorted,
  addMasterTab
} = categorySlice.actions;

export default categorySlice.reducer;
